// src/theme.js

import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Poppins, DM Sans, sans-serif', // Default font stack
    h1: {
      fontFamily: 'Inknut Antiqua, serif',
      fontWeight: 400,
      fontSize: '2.95rem', 
    },
    h2: {
      fontFamily: 'Inknut Antiqua, serif',
      fontWeight: 400,
      fontSize: '2.1rem', 
    },
    h3: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600,
      fontSize: '1.875rem', 
    },
    
    h4: { 
      fontFamily: 'Inknut Antiqua, serif',
      fontWeight: 500,
      fontSize: '1.5rem', 
    },
    subtitle1: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      fontSize: '1rem', 
    },
    body1: {
      fontFamily: 'DM Sans, sans-serif',
      fontWeight: 400,
      fontSize: '1rem', 
    },
  },
  palette: {
    // define theme.palette.background.paper
    background: {
      paper: '#EFEFEF',
    },
    primary: {
      main: '#1B5E20', // GREEN MAIN COLOR
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFFFF0',
      contrastText: '#808078',
    },
    darkGreen: { // Custom color for specific use cases
      main: '#1F4A31',
      contrastText: '#fff',
    },
    error: {
      main: '#FF6347',
    },
    warning: {
      main: '#FFA500',
    },
    // You can add more custom colors if needed
  },
});

export default theme;
