import fetchClient from "../fetchClient";

const businessSignUp = async (payload) =>{
    const url = `/businesses/sign-up/`;
    try {
        const requestOptions = {method: "POST", body: JSON.stringify(payload)}
        const response = await fetchClient(url, requestOptions)
        if (!response.status === 201) {
          throw new Error('Business sign Up failed');
        }
        const data = await response.json();
        console.log('Business SignUp', data);
        return data;
      } catch (error) {
        console.log(error);
      }
}

export default businessSignUp
