// src/pages/Business.js

import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../components/AuthContext';
import { Box, Button, Chip, Typography, Rating, Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Share';
import { useParams, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MapComponent from '../components/Map';
import ContactCard from '../components/ContactCard';
import ReviewCard from '../components/ReviewCard';
import WriteReviewModal from '../components/WriteReviewModal';
import GridModal from '../components/GridModal';
import { hospitalsImage, verticalRest, salonImage, spaImage, restaurantImage } from '../assets';

// Business Component Definition
const Business = () => {
  const { isAuthenticated, userRole, userUUID } = useContext(AuthContext);

  const theme = useTheme();
  const { id } = useParams(); // Get the business ID from the URL
  const location = useLocation(); // Get the current URL
  const [businessData, setBusinessData] = useState(null);
  const [loading, setLoading] = useState(true);

  // State variables
  const [currentReviews, setCurrentReviews] = useState([
    {
      reviewer: 'Supriya Thapa',
      date: '2 weeks ago',
      rating: 5,
      comment:
        'So glad to find this hidden gem in Worcester! The food is superb and I feel welcome by the staff when I walked in.',
      photos: [verticalRest, salonImage, spaImage],
    },
    {
      reviewer: 'John Doe',
      date: '1 month ago',
      rating: 4,
      comment:
        'Great ambiance and delicious menu options. The service was prompt and friendly.',
      photos: [salonImage, hospitalsImage],
    },
    {
      reviewer: 'Jane Smith',
      date: '3 days ago',
      rating: 5,
      comment:
        'Absolutely fantastic experience! Highly recommend to anyone looking for quality food and excellent service.',
      photos: [spaImage],
    },
  ]);
  const [gridModalOpen, setGridModalOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [showToast, setShowToast] = useState(false); // State for the toast message

  // Fetch business data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/businesses/${id}`);
        const data = await response.json();
        setBusinessData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching business details:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!businessData) {
    return <Typography>Business not found.</Typography>;
  }

  // Destructure main business properties
  const {
    name,
    address1,
    city,
    state,
    zip_code,
    country,
    contactNumber,
    description,
    heroImage,
    rating,
    latitude,
    longitude,
    Tags,
  } = businessData;

  // Extract Tags
  const tags = Tags ? Tags.map((tag) => tag.name) : [];

  // Combine address fields and format zip code
  const formattedZipCode = zip_code ? zip_code.toString().split('.')[0] : '';
  const address = `${address1}, ${city}, ${state} ${formattedZipCode}, ${country}`;

  // Define contact object for ContactCard component
  const contact = {
    address: address,
    phone: contactNumber,
    email: businessData.email || '',
    location: {
      lat: latitude ? parseFloat(latitude) : null,
      lng: longitude ? parseFloat(longitude) : null,
    },
  };

  // Convert latitude and longitude to numbers
  const parsedLatitude = latitude ? parseFloat(latitude) : null;
  const parsedLongitude = longitude ? parseFloat(longitude) : null;

  // Define businesses array for the Map component
  const business_location =
    parsedLatitude && parsedLongitude
      ? [
        {
          id: id,
          name: name,
          location: { lat: parsedLatitude, lng: parsedLongitude },
        },
      ]
      : [];

  // Define center and zoom for the map
  const mapCenter = parsedLatitude && parsedLongitude ? { lat: parsedLatitude, lng: parsedLongitude } : null;
  const mapZoom = 15; // Adjust zoom level as needed

  // Handler to delete a review
  const deleteReview = (reviewToDelete) => {
    setCurrentReviews((prevReviews) =>
      prevReviews.filter((review) => review !== reviewToDelete)
    );
  };

  const openGridModal = () => {
    const allPhotos = currentReviews.flatMap((review) => review.photos);
    setPhotos(allPhotos);
    setGridModalOpen(true);
  };

  const closeGridModal = () => setGridModalOpen(false);

  const addReview = (newReview) =>
    setCurrentReviews((prevReviews) => [newReview, ...prevReviews]);

  // Get menuImages from businessData or define as empty array
  const menuImages = businessData.menuImages || [];

  // Handle Share Button Click
  const handleShareClick = () => {
    const url = window.location.origin + location.pathname; // Get current URL
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setShowToast(true);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  // JSX for the Reviews Section
  const ReviewsSection = (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
        Reviews
      </Typography>
      <Box
        sx={{
          width: '100px',
          height: '4px',
          borderRadius: '2px',
          bgcolor: 'primary.main',
          mt: 1,
          mb: 3,
        }}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: menuImages.length > 0 ? '1fr' : 'repeat(auto-fit, minmax(400px, 1fr))',
          gap: 2,
        }}
      >
        {currentReviews.map((review, index) => (
          <ReviewCard
            key={index}
            review={review}
            fullWidth={!menuImages.length}
            onDelete={() => deleteReview(review)}
          />
        ))}
      </Box>
    </Box>
  );

  // Render the Business Component
  return (
    <Box sx={{ bgcolor: theme.palette.background.paper, minHeight: '100vh', p: 1 }}>
      <Header
        isAuthenticated={isAuthenticated}
        userRole={userRole}
        userUUID={userUUID}
        showSearchBar={true}
      />
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '16px',
          margin: 'auto',
          maxWidth: '1200px',
          padding: 3,
          boxShadow: 3,
          mb: 3,
          mt: 4,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            maxHeight: '400px',
            borderRadius: '20px',
            overflow: 'hidden',
          }}
        >
          <Box
            component="img"
            src={heroImage || restaurantImage}
            alt={`${name} Image`}
            sx={{
              width: '100%',
              height: '700px',
              objectFit: 'cover',
            }}
          />
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              fontSize: '1.0rem',
              position: 'absolute',
              borderRadius: '8px',
              bottom: 20,
              right: 20,
              color: 'black',
              backgroundColor: 'white',
              transition: 'transform 0.3s ease, background-color 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                backgroundColor: '#f0f0f0',
              },
            }}
            onClick={openGridModal}
          >
            All Photos
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 4,
            mt: 3,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box sx={{ mb: 2, textAlign: 'left' }}>
              <Typography variant="h1">{name}</Typography>
              <Rating value={rating || 0} precision={0.1} readOnly size="large" />
              <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                {tags.length > 0 ? (
                  tags.slice(0, 5).map((tag, index) => (
                    <Chip key={index} label={tag} size="small" />
                  ))
                ) : (
                  <Typography>No Tags</Typography>
                )}
              </Box>
              <Box sx={{ mb: 2, textAlign: 'left' }}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 'bold', display: 'inline', color: 'success.main' }}
                >
                  Open
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ display: 'inline', ml: 1, color: 'text.secondary' }}
                >
                  (10 am - 10 pm)
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'inline',
                    ml: 2,
                    color: 'primary.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  See opening hours
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <WriteReviewModal onAddReview={addReview} />
              <Button
                variant="outlined"
                color="success"
                startIcon={<ShareIcon />}
                onClick={handleShareClick}
                sx={{
                  fontSize: '1.15rem',
                  borderWidth: '3px',
                  borderRadius: '20px',
                  textTransform: 'none',
                  width: '200px',
                  height: '50px',
                }}
              >
                Share
              </Button>
            </Box>

            {/* Snackbar for Toast Message */}
            <Snackbar
              open={showToast}
              autoHideDuration={3000}
              onClose={() => setShowToast(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert
                onClose={() => setShowToast(false)}
                severity="success"
                sx={{ width: '100%' }}
              >
                Copied to your Clipboard!
              </Alert>
            </Snackbar>

            <Box sx={{ mb: 2, textAlign: 'left' }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Description
              </Typography>
              <Box
                sx={{
                  width: '140px',
                  height: '4px',
                  borderRadius: '2px',
                  bgcolor: 'primary.main',
                  mt: 1,
                }}
              />
              <Typography variant="body1" sx={{ mt: 2 }}>
                {description}
              </Typography>
            </Box>

            {menuImages.length > 0 && (
              <Box sx={{ mb: 2, textAlign: 'left' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  Menu
                </Typography>
                <Box
                  sx={{
                    width: '70px',
                    height: '4px',
                    borderRadius: '2px',
                    bgcolor: 'primary.main',
                    mt: 1,
                  }}
                />
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  {menuImages.map((imgSrc, index) => (
                    <Box
                      component="img"
                      key={index}
                      src={imgSrc}
                      alt="Menu Item"
                      sx={{
                        width: '100%',
                        maxWidth: '200px',
                        borderRadius: '8px',
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {!menuImages.length && <Box>{ReviewsSection}</Box>}
          </Box>

          <Box sx={{ flex: 1 }}>
            <Box sx={{ mb: 2, height: '400px' }}>
              <MapComponent
                businesses={business_location}
                center={mapCenter}
                zoom={mapZoom}
              />
            </Box>
            <ContactCard contact={contact} />
          </Box>
        </Box>

        {menuImages.length > 0 && ReviewsSection}
      </Box>

      <Footer />
      <GridModal open={gridModalOpen} onClose={closeGridModal} photos={photos} />
    </Box>
  );
};

export default Business;
