// src/components/Nav.js

import React from 'react';
import { Link } from "react-router-dom";

// Use relative paths instead of absolute 'src/' paths
import Logo from "./assets/banner.png";
import "./styles/Nav.css";

const Nav = () => {
    return (
        <div className="navDiv">
            <div className="container-fluid">
                <Link to="/">
                    <img src={Logo} alt='Logo' />
                </Link>
            </div>
        </div>
    );
}

export default Nav;
