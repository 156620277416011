import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const PhotoModal = ({ open, onClose, photos, initialIndex }) => {
    const swiperRef = useRef(null);
    const [showPrevButton, setShowPrevButton] = useState(initialIndex > 0);
    const [showNextButton, setShowNextButton] = useState(initialIndex < photos.length - 1);

    const handleSlideChange = (swiper) => {
        setShowPrevButton(swiper.activeIndex > 0);
        setShowNextButton(swiper.activeIndex < photos.length - 1);
    };

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.slideTo(initialIndex, 0);
            setShowPrevButton(initialIndex > 0);
            setShowNextButton(initialIndex < photos.length - 1);
        }
    }, [initialIndex, open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="photo-modal"
            aria-describedby="A modal to display photos in a carousel"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'rgba(0, 0, 0, 0.85)',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '90%',
                    maxWidth: '1200px',
                    height: { xs: '80%', sm: '70%', md: '60%' },
                    bgcolor: 'background.paper',
                    borderRadius: 6,
                    boxShadow: 24,
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '2px solid green',
                    '& .swiper-pagination-bullet': {
                        backgroundColor: 'grey',
                        opacity: 0.8,
                        width: 10,
                        height: 10,
                    },
                    '& .swiper-pagination-bullet-active': {
                        opacity: 1,
                        backgroundColor: 'primary.main',
                    },
                }}
            >
                {/* Close Button */}
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'primary.main',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                        zIndex: 10,
                        width: 45,
                        height: 45,
                        boxShadow: 3,
                    }}
                    aria-label="Close modal"
                >
                    <CloseIcon />
                </IconButton>

                {/* Back Button */}
                {showPrevButton && (
                    <IconButton
                        onClick={() => swiperRef.current?.slidePrev()}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '10px',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'primary.main',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            },
                            zIndex: 10,
                            width: 45,
                            height: 45,
                            boxShadow: 3,
                        }}
                        aria-label="Previous photo"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                )}

                {/* Forward Button */}
                {showNextButton && (
                    <IconButton
                        onClick={() => swiperRef.current?.slideNext()}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'primary.main',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            },
                            zIndex: 10,
                            width: 45,
                            height: 45,
                            boxShadow: 3,
                        }}
                        aria-label="Next photo"
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                )}

                {/* Photo Carousel */}
                <Swiper
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: true }}
                    initialSlide={initialIndex}
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    onSlideChange={handleSlideChange}
                    style={{ width: '100%', height: '100%', paddingBottom: '20px' }} // Adds space at the bottom for pagination
                >
                    {photos.map((photo, index) => (
                        <SwiperSlide key={index}>
                            <Box
                                component="img"
                                src={photo}
                                alt={`Photo ${index + 1}`}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    borderRadius: 4,
                                    padding: 2,
                                    boxSizing: 'border-box',
                                }}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </Modal>
    );
};

export default PhotoModal;
