// src/pages/Explore.js

import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MapComponent from '../components/Map';
import Filters from '../components/Filters';
import VerticalBusinessCard from '../components/VerticalBusinessCard';
import '../styles/Explore.css';
import { Box, Button, Drawer, useMediaQuery, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTheme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router-dom';
import { LocationContext } from '../services/LocationContext';
import queryString from 'query-string'; // Import query-string for parsing URL query parameters
import { AuthContext } from '../components/AuthContext';


const Explore = () => {
  const { isAuthenticated, userRole, userUUID } = useContext(AuthContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // md and down
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg')); // lg and up
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { category } = useParams(); // Get category from URL path
  const location = useLocation(); // Get current location object
  const { keywords, city: queryCity, state: queryState } = queryString.parse(location.search); // Parse query parameters

  const { city, region, loading } = useContext(LocationContext); // Extract city and region from context

  // Determine effective city and region: use query params if available, else fallback to context
  const effectiveCity = queryCity || city;
  const effectiveRegion = queryState || region;

  const [businesses, setBusinesses] = useState([]);
  const [totalBusinesses, setTotalBusinesses] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10; // Number of businesses per page

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // Fetch businesses based on category or keywords and location
  const fetchBusinesses = async (page = 1) => {
    if (!effectiveCity || !effectiveRegion) {
      console.warn('City or region is missing.');
      setBusinesses([]);
      setTotalBusinesses(0);
      return;
    }

    try {
      let response;
      if (keywords) {
        // Call the /search endpoint
        response = await fetch(
          `${process.env.REACT_APP_API_URL}/businesses/search?keywords=${encodeURIComponent(
            keywords
          )}&city=${encodeURIComponent(effectiveCity)}&state=${encodeURIComponent(
            effectiveRegion
          )}&page=${page}&perPage=${perPage}`
        );
      } else if (category) {
        // Call the /top-businesses-by-category endpoint
        response = await fetch(
          `${process.env.REACT_APP_API_URL}/businesses/top-businesses-by-category?category=${encodeURIComponent(
            category
          )}&city=${encodeURIComponent(effectiveCity)}&state=${encodeURIComponent(
            effectiveRegion
          )}&page=${page}&perPage=${perPage}`
        );
      } else {
        // Handle case where neither keywords nor category is provided
        console.warn('No keywords or category provided.');
        setBusinesses([]);
        setTotalBusinesses(0);
        return;
      }

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      // Map businesses to include 'location' field
      const mappedBusinesses = data.businesses
        .filter(
          (business) =>
            business.latitude &&
            business.longitude &&
            !isNaN(business.latitude) &&
            !isNaN(business.longitude)
        )
        .map((business) => ({
          ...business,
          location: {
            lat: parseFloat(business.latitude),
            lng: parseFloat(business.longitude),
          },
        }));

      setBusinesses(mappedBusinesses);
      setTotalBusinesses(data.total);
      setCurrentPage(data.page);
    } catch (error) {
      console.error('Error fetching businesses:', error);
      // Optionally, set an error state here to display to the user
    }
  };

  useEffect(() => {
    if ((category || keywords) && !loading) {
      fetchBusinesses(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, keywords, effectiveCity, effectiveRegion, loading, currentPage]);

  // Function to capitalize the first letter
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  // Determine the tagline based on whether it's a search or category view
  const defaultTagline = 'Explore the local favorites near you';
  let tagline = defaultTagline;

  if (keywords && effectiveCity && effectiveRegion) {
    tagline = (
      <>
        Search results for{' '}
        <Box component="span" sx={{ color: theme.palette.primary.main }}>
          "{capitalize(keywords)}"
        </Box>{' '}
        in{' '}
        <Box component="span" sx={{ color: theme.palette.primary.main }}>
          {capitalize(effectiveCity)}, {capitalize(effectiveRegion)}
        </Box>
      </>
    );
  } else if (category && effectiveCity && effectiveRegion) {
    tagline = (
      <>
        Top{' '}
        <Box component="span" sx={{ color: theme.palette.primary.main }}>
          {capitalize(category)}
        </Box>{' '}
        in{' '}
        <Box component="span" sx={{ color: theme.palette.primary.main }}>
          {capitalize(effectiveCity)}, {capitalize(effectiveRegion)}
        </Box>
      </>
    );
  }

  // Function to calculate the map view based on business locations
  const calculateMapView = (businessList) => {
    if (!businessList || businessList.length === 0) {
      return {
        center: { lat: 42.2626, lng: -71.8023 }, // Default center (e.g., Worcester, MA)
        zoom: 12,
      };
    }

    const bounds = {
      north: -90,
      south: 90,
      east: -180,
      west: 180,
    };

    businessList.forEach((business) => {
      const { lat, lng } = business.location;
      bounds.north = Math.max(bounds.north, lat);
      bounds.south = Math.min(bounds.south, lat);
      bounds.east = Math.max(bounds.east, lng);
      bounds.west = Math.min(bounds.west, lng);
    });

    return {
      center: {
        lat: (bounds.north + bounds.south) / 2,
        lng: (bounds.east + bounds.west) / 2,
      },
      zoom: 13,
    };
  };

  return (
    <div
      className="Explore"
      style={{
        backgroundColor: theme.palette.background.paper,
        paddingTop: '40px',
      }}
    >
<Header
  isAuthenticated={isAuthenticated}
  userRole={userRole}
  userUUID={userUUID}
  showSearchBar={true}
/>
      {/* Tagline */}
      <Box
        sx={{
          padding: '16px',
          backgroundColor: theme.palette.background.paper,
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h3"
          component="h2"
          sx={{
            textAlign: 'left',
            fontWeight: 400,
          }}
        >
          {tagline}
        </Typography>
      </Box>

      {/* Filters Section */}
      {!isSmallScreen ? (
        <div
          style={{
            padding: '16px',
            backgroundColor: theme.palette.background.paper,
            borderBottom: '1px solid #ddd',
          }}
        >
          <Filters />
        </div>
      ) : (
        <div
          className="hamburger-button-container"
          style={{ padding: '16px', textAlign: 'center' }}
        >
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={toggleDrawer(true)}
            sx={{
              borderRadius: '20px',
              px: 2,
              py: 1,
              textTransform: 'none',
              fontSize: theme.typography.subtitle1.fontSize,
              borderColor: theme.palette.darkGreen.main,
              color: theme.palette.darkGreen.main,
              '&:hover': {
                borderColor: theme.palette.darkGreen.dark || '#163922',
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            Filters
          </Button>
        </div>
      )}

      {/* Drawer for Filters on Small Screens */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div
          className="drawer-content"
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          style={{ width: '250px' }} // Adjust the drawer width if needed
        >
          <Filters />
        </div>
      </Drawer>

      {/* Main Content: Business List and Map */}
      <Box
        className="main-content"
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        sx={{ gap: 2, p: 2 }}
      >
        <Box
          className="business-list"
          sx={{
            flex: isSmallScreen
              ? 'none'
              : isLargeScreen
              ? '0 0 700px'
              : '0 0 350px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            maxHeight: 'calc(100vh - 350px)',
          }}
        >
          {/* Business Cards Container */}
          <Box
            sx={{
              flex: 1,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: isLargeScreen ? 'repeat(2, 1fr)' : '1fr',
                  gap: 2,
                }}
              >
                {businesses.length > 0 ? (
                  businesses.map((business) => (
                    <VerticalBusinessCard
                      key={business.id}
                      business={business}
                      showDescription={true}
                    />
                  ))
                ) : (
                  <Typography variant="h6">
                    {category || keywords
                      ? 'No businesses found.'
                      : 'Loading...'}
                  </Typography>
                )}
              </Box>

              {/* Pagination Controls */}
              {businesses.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 2,
                    mt: 2,
                    borderTop: '1px solid #ddd',
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (currentPage > 1) {
                        const newPage = currentPage - 1;
                        setCurrentPage(newPage);
                        fetchBusinesses(newPage);
                      }
                    }}
                    disabled={currentPage === 1}
                    sx={{
                      mr: 2,
                      borderRadius: '20px',
                      textTransform: 'capitalize',
                      fontSize: theme.typography.subtitle1.fontSize,
                      backgroundColor: theme.palette.darkGreen.main,
                      '&:hover': {
                        backgroundColor: theme.palette.darkGreen.dark || '#163922',
                      },
                    }}
                  >
                    Previous
                  </Button>
                  <Typography
                    variant="body1"
                    sx={{
                      mx: 2,
                      fontSize: theme.typography.subtitle1.fontSize,
                    }}
                  >
                    Page {currentPage} of {Math.ceil(totalBusinesses / perPage)}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      const totalPages = Math.ceil(totalBusinesses / perPage);
                      if (currentPage < totalPages) {
                        const newPage = currentPage + 1;
                        setCurrentPage(newPage);
                        fetchBusinesses(newPage);
                      }
                    }}
                    disabled={currentPage >= Math.ceil(totalBusinesses / perPage)}
                    sx={{
                      ml: 2,
                      borderRadius: '20px',
                      textTransform: 'capitalize',
                      fontSize: theme.typography.subtitle1.fontSize,
                      backgroundColor: theme.palette.darkGreen.main,
                      '&:hover': {
                        backgroundColor: theme.palette.darkGreen.dark || '#163922',
                      },
                    }}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {/* Map Component */}
        <Box
          className="map"
          sx={{
            flex: 1,
            height: isSmallScreen ? '300px' : 'auto',
            minHeight: '300px',
          }}
        >
          {businesses.length > 0 ? (
            <MapComponent
              businesses={businesses}
              {...calculateMapView(businesses)}
            />
          ) : (
            <Typography variant="h6">Loading map...</Typography>
          )}
        </Box>
      </Box>

      <Footer />
    </div>
  );
};

export default Explore;
