import fetchClient from "../fetchClient";

const user = async () =>{
    const url = "/user/";
    try {
        const requestOptions = {method: "GET"}
        const response = await fetchClient(url, requestOptions)
        console.log(response)
        if (!response.ok) {
          throw new Error('Could not get user');
        }
  
        const data = await response.json();
        console.log('Got user', data);
        return data;
      } catch (error) {
        console.log(error);
      }
}

const userDetail = async (uuid) =>{
    const url = `/user/${uuid}/`;
    try {
        const requestOptions = {method: "GET"}
        const response = await fetchClient(url, requestOptions)
        console.log(response)
        if (!response.ok) {
          throw new Error('Could not get user');
        }
  
        const data = await response.json();
        console.log('Got user', data);
        return data;
      } catch (error) {
        console.log(error);
      }
}


export default {user, userDetail}