// src/components/VerticalBusinessCard.js

import React from 'react';
import { Box, Typography, Rating, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const VerticalBusinessCard = ({ business, showDescription = false }) => {
  const navigate = useNavigate();

  // Safely determine if the business has a price
  const hasPrice = business.price && business.price.length > 0;

  // Safely determine the number of tags to display
  const maxTags = hasPrice
    ? 3
    : business.Tags && Array.isArray(business.Tags)
    ? business.Tags.length
    : 0;

  // Format price (e.g., '$', '$$', '$$$')
  const priceDisplay = business.price ? business.price : '';

  // Determine the image source
  const imageSrc =
    business.heroImage ||
    business.imageUrl ||   // For data from /top-businesses-by-category
    business.image_url ||  // For data from other sources
    'https://via.placeholder.com/300x300';

  return (
    <Box
      onClick={() => navigate(`/business/${business.id}`)}
      sx={{
        cursor: 'pointer',
        '&:hover .business-image': {
          transform: 'scale(1.05)',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          boxSizing: 'border-box',
        }}
      >
        {/* Business Image */}
        <Box
          sx={{
            width: '100%',
            height: '220px',
            overflow: 'hidden',
            borderRadius: '12px',
          }}
        >
          <Box
            component="img"
            src={imageSrc}
            alt={`Image of ${business.name}`}
            className="business-image"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transition: 'transform 0.3s ease-in-out',
            }}
          />
        </Box>

        {/* Business Details */}
        <Box sx={{ width: '100%', mt: 2 }}>
          {/* Business Name */}
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontSize: '1.2rem',
              fontWeight: 600,
              textAlign: 'left',
              mb: 1,
            }}
          >
            {business.name}
          </Typography>

          {/* Rating */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1,
            }}
          >
            <Rating
              value={business.rating}
              precision={0.1}
              readOnly
              size="small"
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 0.5 }}
            >
              {business.rating.toFixed(1)}
            </Typography>
          </Box>

          {/* Tags and Price */}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: 0.5,
              mb: 1,
            }}
          >
            {/* Price */}
            {priceDisplay && (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  marginRight: 1,
                  color: 'text.secondary',
                }}
              >
                {priceDisplay}
              </Typography>
            )}

            {/* Tags */}
            {business.Tags && Array.isArray(business.Tags) && business.Tags.length > 0 && (
              business.Tags.slice(0, maxTags).map((tag, index) => (
                <Chip
                  key={`${tag.id}-${index}`}
                  label={tag.name}
                  size="small"
                  sx={{
                    fontSize: '0.8rem',
                    maxWidth: '100px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                />
              ))
            )}
          </Box>

          {/* Description */}
          {showDescription && business.description && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {business.description}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VerticalBusinessCard;
