// src/components/ContactCard.js

import React, { useState, useContext } from 'react';
import { Box, Typography, Button, TextField, Card, Divider, Alert } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DirectionsIcon from '@mui/icons-material/Directions';
import { AuthContext } from './AuthContext'; // Adjust the import path as needed

const ContactCard = ({ contact = {} }) => {
  const { isAuthenticated } = useContext(AuthContext); // Access authentication status
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm((prev) => !prev);
  };

  const handleGetDirections = () => {
    if (contact.address) {
      const encodedAddress = encodeURIComponent(contact.address);
      const url = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
      window.open(url, '_blank');
    }
  };

  return (
    <Card sx={{ padding: 2, width: 'auto', boxShadow: 3, borderRadius: '16px', backgroundColor: 'White' }}>
      <Box sx={{ textAlign: 'left' }}>

        {/* Conditionally Render Email */}
        {contact.email && (
          <Box display="flex" alignItems="center" mb={2}>
            <EmailIcon sx={{ color: 'darkgreen', mr: 2 }} />
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'darkgreen', textDecoration: 'underline' }}>
              {contact.email}
            </Typography>
          </Box>
        )}
        {contact.email && <Divider />}

        {/* Conditionally Render Phone */}
        {contact.phone && (
          <Box display="flex" alignItems="center" my={2}>
            <PhoneIcon sx={{ color: 'darkgreen', mr: 2 }} />
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'darkgreen' }}>
              {contact.phone}
            </Typography>
          </Box>
        )}
        {contact.phone && <Divider />}

        {/* Conditionally Render Address */}
        {contact.address && (
          <Box display="flex" alignItems="center" my={2}>
            <LocationOnIcon sx={{ color: 'darkgreen', mr: 2 }} />
            <Box flex={1}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'darkgreen' }}>
                Address
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {contact.address}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DirectionsIcon />}
              onClick={handleGetDirections}
              sx={{
                borderRadius: '20px',
                borderWidth: '3px',
                textTransform: 'none',
                ml: 2,
                fontSize: '1rem',
                padding: '8px 16px',
              }}
            >
              Get Directions
            </Button>
          </Box>
        )}

        <Divider sx={{ my: 2 }} />

        {/* Button to expand the form */}
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="text"
            color="primary"
            endIcon={<ExpandMoreIcon />}
            onClick={toggleForm}
            sx={{
              textTransform: 'none',
              fontSize: '1.1rem',
              maxHeight: '32px',
            }}
          >
            {showForm ? "Hide Form" : "Ask a Question"}
          </Button>
        </Box>

        {/* Expandable form section */}
        {showForm && (
          <Box mt={2}>
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder="Type your question here"
              sx={{ mt: 1 }}
              disabled={!isAuthenticated} // Disable input if not authenticated
              InputProps={{
                readOnly: !isAuthenticated, // Make input read-only if not authenticated
              }}
            />
            {/* Display message if not authenticated */}
            {!isAuthenticated && (
              <Alert severity="info" sx={{ mt: 1 }}>
                Log in or create an account to ask this business a question.
              </Alert>
            )}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                variant="contained" 
                color="primary" 
                sx={{ mr: 1 }} 
                disabled={!isAuthenticated} // Disable button if not authenticated
              >
                Submit
              </Button>
              <Button variant="outlined" onClick={toggleForm}>Cancel</Button>
            </Box>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default ContactCard;
