// src/components/Footer.js

import React from 'react';
import { Box, Container, Grid2, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

function Footer() {
  const theme = useTheme();

  return (
    <Box component="footer" sx={{ backgroundColor: theme.palette.background.paper, borderTop: '1px solid #e0e0e0', py: 4 }}>
      <Container maxWidth="lg">
        <Grid2 container spacing={4} justifyContent="space-between" alignItems="center">
          <Grid2 xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Typography variant="h6" sx={{ fontFamily: theme.typography.h6.fontFamily, color: '#4D4D4D' }}>
              LIAN
            </Typography>
            <Typography variant="subtitle1" sx={{ fontFamily: theme.typography.subtitle1.fontFamily, color: '#4D4D4D' }}>
              Cognitio Et Potentia
            </Typography>
          </Grid2>
          <Grid2 xs={12} md={4} sx={{ textAlign: 'center' }}>
            <MuiLink
              component={Link}
              to="/terms"
              sx={{
                color: theme.palette.darkGreen.main,
                textDecoration: 'none',
                mx: 1,
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Terms & Conditions
            </MuiLink>
            |
            <MuiLink
              component={Link}
              to="/privacy"
              sx={{
                color: theme.palette.darkGreen.main,
                textDecoration: 'none',
                mx: 1,
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Privacy Policy
            </MuiLink>
          </Grid2>
          <Grid2 xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
            <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.5)' }}>
              Copyright © 2024 LIAN. All rights reserved.
            </Typography>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}

export default Footer;
