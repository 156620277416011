import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const role = queryParams.get('role');
    const uuid = queryParams.get('uuid');

    if (token) localStorage.setItem('token', token);
    if (role) localStorage.setItem('role', role);
    if (uuid) localStorage.setItem('uuid', uuid);

    navigate('/');
  }, [location, navigate]);

  return null;  // This page doesn't need to render anything
};

export default LoginRedirect;
