import fetchClient from "../fetchClient";

const emailVerify = async (id, key) =>{
    const url = `/verify/email/${id}/${key}/`;
    try {
        const requestOptions = {method: "GET"}
        const response = await fetchClient(url, requestOptions)
        if (!response.status === 200) {
          throw new Error('Email verify failed');
        }
        const data = await response.json();
        console.log('Verified email:', data);
        return data;
      } catch (error) {
        console.log(error);
      }
}

export default emailVerify
