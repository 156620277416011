// src/pages/Home.js

import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  heroImage1,
  heroImage2,
  heroImage3,
} from '../assets'; // Remove unused images
import Header from '../components/Header';
import Footer from '../components/Footer';
import SearchBarComponent from '../components/SearchBar';
import ImageCarousel from '../components/ImageCarousel';
import CategoryCarousel from '../components/CategoryCarousel';
import BusinessCarousel from '../components/BusinessCarousel';
import { AuthContext } from '../components/AuthContext';
import { LocationContext } from '../services/LocationContext';

function Home() {
  const theme = useTheme();
  const { city, region } = useContext(LocationContext);
  const { isAuthenticated, userRole, userUUID } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(0);
  const [authStatus, setAuthStatus] = useState(isAuthenticated);
  const [authRole, setAuthRole] = useState(userRole);
  const [authUUID, setAuthUUID] = useState(userUUID);
  const [localFavorites, setLocalFavorites] = useState([]);
  const [bestRestaurants, setBestRestaurants] = useState([]);

  useEffect(() => {
    setAuthStatus(localStorage.getItem('token') ? true : false);
    setAuthRole(localStorage.getItem('role'));
    setAuthUUID(localStorage.getItem("uuid"));

    if (city && region) {
      // Fetch top 10 businesses excluding "Food & Drink"
      fetch(
        `${process.env.REACT_APP_API_URL}/businesses/top-10-businesses?city=${city}&state=${region}`
      )
        .then((response) => response.json())
        .then((data) => {
          const mappedData = data.map((business) => ({
            id: business.id,
            name: business.name,
            imageUrl: business.heroImage || business.image_url,
            rating: business.rating,
            price: business.price,
            Tags: business.Tags.map((tag) => ({
              id: tag.id,
              name: tag.name,
            })),
            // Note: We are not including description here
          }));
          setLocalFavorites(mappedData);
        })
        .catch((error) => console.error('Error fetching top businesses:', error));

      // Fetch top 10 restaurants in "Food & Drink"
      fetch(
        `${process.env.REACT_APP_API_URL}/businesses/top-10-restaurants?city=${city}&state=${region}`
      )
        .then((response) => response.json())
        .then((data) => {
          const mappedData = data.map((business) => ({
            id: business.id,
            name: business.name,
            imageUrl: business.heroImage || business.image_url,
            rating: business.rating,
            price: business.price,
            Tags: business.Tags.map((tag) => ({
              id: tag.id,
              name: tag.name,
            })),
            // Note: We are not including description here
          }));
          setBestRestaurants(mappedData);
        })
        .catch((error) =>
          console.error('Error fetching top restaurants:', error)
        );
    }
  }, [city, region, refresh]); // Removed loc.pathname

  const handleNavigateHome = () => {
    console.log("Not work");
    setRefresh((prev) => prev + 1);
  };

  // Updated data for categories with icons
  const categories = [
    { label: 'Food & Drink', icon: 'BrunchDining' },
    { label: 'Retail', icon: 'ShoppingCart' },
    { label: 'Services', icon: 'Construction' },
    { label: 'Entertainment & Recreation', icon: 'Attractions' },
    { label: 'Health & Wellness', icon: 'HealthAndSafety' },
    { label: 'Community & Government', icon: 'AssuredWorkload' },
    { label: 'Other', icon: 'Category' },
  ];

  return (
    <Box
      className="home-container"
      sx={{ position: 'relative', backgroundColor: theme.palette.background.paper }}
    >
      <Header isAuthenticated={authStatus} userRole={authRole} userUUID={authUUID} handleNavigateHome={handleNavigateHome} />

      <Box
        component="header"
        sx={{
          position: 'relative',
          width: '100%',
          minHeight: { xs: '200px', md: '500px' },
          backgroundColor: theme.palette.background.paper,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <ImageCarousel images={[heroImage1, heroImage2, heroImage3]}>
          <Box
            sx={{
              position: 'absolute',
              top: '15%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: { xs: '90%', sm: '80%', md: '900px' },
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '16px',
              padding: theme.spacing(3),
              textAlign: 'center',
              zIndex: 2,
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontWeight: 'bold',
                color: theme.palette.text.primary,
                fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
                lineHeight: 1.2,
              }}
            >
              <Box component="span" sx={{ color: theme.palette.darkGreen.main }}>
                Find
              </Box>{' '}
              What You Need,
              <br />
              <Box component="span" sx={{ color: theme.palette.darkGreen.main }}>
                Right Where You Are
              </Box>
            </Typography>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              top: '62%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: { xs: '90%', sm: '80%', md: '1000px' },
              zIndex: 2,
            }}
          >
            <SearchBarComponent />
          </Box>
        </ImageCarousel>
      </Box>

      <Box sx={{ py: 6, px: 2, backgroundColor: theme.palette.background.paper }}>
        <BusinessCarousel
          title={
            <>
              Best Restaurants in{' '}
              <Typography
                variant="h3"
                component="span"
                sx={{ color: theme.palette.primary.main }}
              >
                {city || 'Your City'}
              </Typography>
            </>
          }
          businesses={bestRestaurants}
        />

        <BusinessCarousel
          title={
            <>
              Local Favorites in{' '}
              <Typography
                variant="h3"
                component="span"
                sx={{ color: theme.palette.primary.main }}
              >
                {city || 'Your City'}
              </Typography>
            </>
          }
          businesses={localFavorites}
        />
      </Box>

      <Box sx={{ py: 6, px: 2, backgroundColor: '#E0DAD5' }}>
        <Box
          sx={{
            maxWidth: '1200px',
            margin: '0 auto',
            padding: theme.spacing(4),
            borderRadius: '8px',
          }}
        >
          <Typography
            variant="h3"
            sx={{ mb: 2, textAlign: 'left', fontWeight: 400 }}
          >
            Browse By Category
          </Typography>
          <CategoryCarousel categories={categories} />
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}

export default Home;
