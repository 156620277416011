//import { Form, Link } from "react-router-dom"
import {
    Card, Container, CardContent, Box, TextField, Button, Input, Typography
} from '@mui/material';
import Nav from "../Nav"
//import "../styles/Regipage.css"
import { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import businessSignUp from "../services/business"
import SwalFire from '../components/SwalFire';
import { green } from '@mui/material/colors';
//import ico from '../assets/ico.png';

const RegiPage2 = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [setSelectedFile] = useState(null);
    const [userEmail, setUserEmail] = useState(location.state?.userEmail)
    const [zip, setZip] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [address, setAddress] = useState("")
    const [country, setCountry] = useState("")
    const [contact, setContact] = useState("")
    const [description, setDescription] = useState("")
    const handleFileUpload = (event) => {
        setSelectedFile(event.target.files[0]);
        console.log(event.target.files[0]);

    };

    const handleSubmit = async (e) =>{
        e.preventDefault()
        const payload = {userEmail: userEmail, zip: zip, city: city, state: state, address: address, country: country,
            contact: contact, description: description
        }
        const res = await businessSignUp(payload)
        if (res){
            console.log("Success", res)
            SwalFire({title: "", text: "Business Signup success", background: "green", icon: "success"})
            navigate('/login/')
        }else{
            console.log("Fail")
            SwalFire({title: "", text: "Business Signup failed", background: "red", icon: "error"})
            navigate('/regipage_2/')
        }

    }

    useEffect(()=>{
        console.log(userEmail, "<------")
    })

    return (
        <div>
            <Nav />
            <br/>
            <div style={{ height: "6rem" }}></div>
            <Container maxWidth="sm">
                <Card sx={{ width: '100%', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: "10px" }}>
                    <CardContent sx={{ textAlign: "left" }}>
                        <h1>Welcome to <span style={{ color: "#1F4A31" }}>LIAN</span> </h1>
                        <div>
                            <Box onSubmit={handleSubmit} component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>Business Hero Image</p>
                                <Button variant="contained" component="label">
                                    Choose Image
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        // onChange={handleImageChange}
                                        sx={{ display: 'none' }}
                                    />
                                </Button>
                                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>Address</p>
                                <TextField
                                    placeholder="Address"
                                    type="text" //  Company's name
                                    variant="outlined"
                                    required
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>City</p>
                                <TextField
                                    placeholder="City"
                                    type="text" //   Owner's Name
                                    variant="outlined"
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>State</p>
                                <TextField
                                    placeholder="State"
                                    type="text" // NOT DEFINED YET
                                    variant="outlined"
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                />
                                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>Zip Code</p>
                                <TextField
                                    placeholder="Zip Code"
                                    type="text" // NOT DEFINED YET
                                    variant="outlined"
                                    onChange={(e) => setZip(e.target.value)}
                                    required
                                />
                                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>Country</p>
                                <TextField
                                    placeholder="Country"
                                    type="text" // NOT DEFINED YET
                                    variant="outlined"
                                    onChange={(e) => setCountry(e.target.value)}
                                    required
                                />
                                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>Contact Number</p>
                                <TextField
                                    placeholder="Contact Number"
                                    type="text" // NOT DEFINED YET
                                    variant="outlined"
                                    onChange={(e) => setContact(e.target.value)}
                                    required
                                />
                                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>Description</p>
                                <TextField
                                    multiline
                                    placeholder='Description'
                                    onChange={(e) => setDescription(e.target.value)}
                                    rows={4}
                                >
                                </TextField>
                                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                                    <Button type='submit' style={{
                                        width:
                                            "60%", backgroundColor: "#1F4A31",
                                        color: "white", borderRadius: "5px",
                                        padding: "10px", fontWeight: "500", fontSize: "1rem"
                                    }}>Submit</Button>
                                </div>
                            </Box>
                        </div>
                    </CardContent>
                </Card>
            </Container>
            <br/><br/>
            <Footer/>
        </div>
    )
}

export default RegiPage2