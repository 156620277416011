// src/components/Filters.js

import React, { useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  TextField,
  Chip,
  Rating,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledResetButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.darkGreen.main,
  color: theme.palette.darkGreen.contrastText,
  fontSize: theme.typography.subtitle1.fontSize,
  borderRadius: '30px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.darkGreen.dark || '#163922',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
  '& .MuiInputBase-root': {
    borderRadius: '30px',
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.subtitle1.fontSize,
    height: '45px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.darkGreen.main, // Set border color to dark green
    },
    '&:hover fieldset': {
      borderColor: theme.palette.darkGreen.main, // Keep the border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.darkGreen.main, // Keep the border color when focused
    },
  },
  '& .MuiInputLabel-root': {
    top: '-4px',
    color: theme.palette.darkGreen.main, // Label color
    '&.Mui-focused': {
      color: theme.palette.darkGreen.main, // Label color when focused
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  minWidth: 200,
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    padding: theme.spacing(0.5, 3),
    fontSize: theme.typography.subtitle1.fontSize,
    height: '45px',
    '& fieldset': {
      borderColor: theme.palette.darkGreen.main, // Set border color to dark green
    },
    '&:hover fieldset': {
      borderColor: theme.palette.darkGreen.main, // Keep the border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.darkGreen.main, // Keep the border color when focused
    },
  },
  '& .MuiInputLabel-root': {
    top: '-4px',
    color: theme.palette.darkGreen.main, // Label color
    '&.Mui-focused': {
      color: theme.palette.darkGreen.main, // Label color when focused
    },
  },
}));

function Filters() {
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState([]);
  const [minRating, setMinRating] = useState(0);
  const [openNow, setOpenNow] = useState(false);

  const [availableTags] = useState([
    'Outdoor Seating',
    'Free Wi-Fi',
    'Pet Friendly',
    'Live Music',
    'Happy Hour',
    'Family Friendly',
    'Vegetarian Options',
  ]);

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleTagsChange = (event, newTags) => {
    setTags(newTags);
  };

  const handleMinRatingChange = (event, newValue) => {
    setMinRating(newValue);
  };

  const handleOpenNowChange = (event) => {
    setOpenNow(event.target.checked);
  };

  const handleReset = () => {
    setPrice('');
    setCategory('');
    setTags([]);
    setMinRating(0);
    setOpenNow(false);
  };

  const handleDeleteChip = (chipType, chipValue) => {
    if (chipType === 'price') setPrice('');
    else if (chipType === 'category') setCategory('');
    else if (chipType === 'tag') setTags(tags.filter((tag) => tag !== chipValue));
    else if (chipType === 'minRating') setMinRating(0);
    else if (chipType === 'openNow') setOpenNow(false);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Box display="flex" alignItems="center" gap={2} sx={{ mb: 2, flexWrap: 'wrap' }}>
        <StyledFormControl variant="outlined">
          <InputLabel>Price</InputLabel>
          <Select
            value={price}
            onChange={handlePriceChange}
            label="Price"
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: '10px',
                },
              },
            }}
          >
            <MenuItem value="">Any</MenuItem>
            <MenuItem value="$">Low ($)</MenuItem>
            <MenuItem value="$$">Medium ($$)</MenuItem>
            <MenuItem value="$$$">High ($$$)</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl variant="outlined">
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            onChange={handleCategoryChange}
            label="Category"
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: '10px',
                },
              },
            }}
          >
            <MenuItem value="">Any</MenuItem>
            <MenuItem value="restaurant">Restaurant</MenuItem>
            <MenuItem value="cafe">Cafe</MenuItem>
            <MenuItem value="bar">Bar</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledAutocomplete
          multiple
          options={availableTags}
          value={tags}
          onChange={handleTagsChange}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option}
                label={option}
                {...getTagProps({ index })}
                size="small"
                sx={{ fontSize: '0.9rem' }}
                onDelete={() => handleDeleteChip('tag', option)}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Tags"
              placeholder="Search Tags"
            />
          )}
        />

        {/* Rating Filter */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ mr: 1 }}>
            Min Rating:
          </Typography>
          <Rating
            name="min-rating"
            value={minRating}
            onChange={handleMinRatingChange}
            precision={0.5}
          />
        </Box>

        {/* Open Now Filter */}
        <FormControlLabel
          control={
            <Switch
              checked={openNow}
              onChange={handleOpenNowChange}
              color="primary"
            />
          }
          label="Open Now"
        />

        <StyledResetButton onClick={handleReset}>
          Reset Filters
        </StyledResetButton>
      </Box>

      {/* Display Applied Filters */}
      <Box display="flex" flexWrap="wrap" gap={1}>
        {price && (
          <Chip
            label={`Price: ${price}`}
            size="medium"
            onDelete={() => handleDeleteChip('price')}
            sx={{ fontSize: '0.9rem' }}
          />
        )}
        {category && (
          <Chip
            label={`Category: ${category}`}
            size="medium"
            onDelete={() => handleDeleteChip('category')}
            sx={{ fontSize: '0.9rem' }}
          />
        )}
        {tags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            size="medium"
            onDelete={() => handleDeleteChip('tag', tag)}
            sx={{ fontSize: '0.9rem' }}
          />
        ))}
        {minRating > 0 && (
          <Chip
            label={`Min Rating: ${minRating}`}
            size="medium"
            onDelete={() => handleDeleteChip('minRating')}
            sx={{ fontSize: '0.9rem' }}
          />
        )}
        {openNow && (
          <Chip
            label="Open Now"
            size="medium"
            onDelete={() => handleDeleteChip('openNow')}
            sx={{ fontSize: '0.9rem' }}
          />
        )}
      </Box>
    </Box>
  );
}

export default Filters;
