import {
  Card, Container, CardContent, Box, TextField, Button, Tooltip, Typography, Link as MuLink
} from '@mui/material';
import Nav from "../Nav";
import { useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Footer from "../components/Footer"
import "../styles/Login.css";
import SwalFire from "../components/SwalFire"
import { Link, useNavigate } from 'react-router-dom';
import userSignUp from '../services/userSignUp';

const SignUpUser = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate()

  // Validation functions
  const isLengthValid = password.length >= 8 && password.length <= 16;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*]/.test(password);

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      SwalFire({ title: "Passwords did not match!", text: "", icon: "error", background: "red" })
    } else {
      const data = { fullName: fullName, email: email, password: password }
      const result = await userSignUp(data)
      if (result.email) {
        console.log("User added successfully!")
        SwalFire({
          title: "Signup Success!", text: "A verification link has been sent to your email",
          icon: "success", background: "green", color: "white", timer: 5000
        })
        navigate("/login")
      }else{
        SwalFire({
          title: result.message, text: "",
          icon: "error", background: "red", color: "white", timer: 5000
        })
      }
    }
  }

  return (
    <div>
      <Nav />
      <div style={{ height: "6rem" }}></div>
      <Container maxWidth="sm">
        <Card sx={{ width: '100%', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: "10px" }}>
          <CardContent sx={{ textAlign: "left" }}>
            <h1>Welcome to <span style={{ color: "#1F4A31" }}>LIAN</span></h1>
            <div>
              <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* User's Name Field */}
                <div style={{ margin: '0' }}>
                  <Typography variant="body1" sx={{ fontSize: '16px', margin: '0', color: '#1D407B', fontFamily: 'Montserrat, sans-serif' }}>
                    Full Name
                  </Typography>
                  <TextField
                    required
                    placeholder="Full Name"
                    sx={{ width: '100%', height: '72px' }}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                {/* Email Field */}
                <div style={{ margin: '0' }}>
                  <Typography variant="body1" sx={{ fontSize: '16px', margin: '0', color: '#1D407B', fontFamily: 'Montserrat, sans-serif' }}>
                    Email
                  </Typography>
                  <TextField
                    variant="outlined"
                    type='email'
                    required
                    placeholder="abc@gmail.com"
                    sx={{ width: '100%', height: '72px' }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {/* Password Field with Tooltip */}
                <div style={{ margin: '0' }}>
                  <Typography variant="body1" sx={{ fontSize: '16px', margin: '0', color: '#1D407B', fontFamily: 'Montserrat, sans-serif' }}>
                    Password
                  </Typography>
                  <Tooltip
                    title={
                      <div style={{ padding: '0px', margin: '1px' }}>
                        <Typography variant="body2" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: '12px', marginBottom: '5px' }}>
                          Password Policy
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 500, fontSize: '14px', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                          <CheckCircleIcon sx={{ fontSize: '16px', color: isLengthValid ? 'green' : 'transparent', marginRight: '5px' }} />
                          Minimum 8 to 16 characters
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 500, fontSize: '14px', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                          <CheckCircleIcon sx={{ fontSize: '16px', color: hasUpperCase ? 'green' : 'transparent', marginRight: '5px' }} />
                          Contains an uppercase letter
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 500, fontSize: '14px', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                          <CheckCircleIcon sx={{ fontSize: '16px', color: hasNumber ? 'green' : 'transparent', marginRight: '5px' }} />
                          Contains a number (0-9)
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 500, fontSize: '14px', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                          <CheckCircleIcon sx={{ fontSize: '16px', color: hasSpecialChar ? 'green' : 'transparent', marginRight: '5px' }} />
                          Contains a special character (!@#&$)
                        </Typography>
                      </div>
                    }
                    arrow
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "#1F4A31",
                          color: 'white',
                          padding: '12px',
                          borderRadius: '4px',
                          fontFamily: 'Montserrat, sans-serif',
                        },
                      },
                      arrow: {
                        sx: {
                          color: "#1F4A31",
                        },
                      },
                    }}
                  >
                    <TextField
                      type="password"
                      variant="outlined"
                      required
                      placeholder="Password"
                      sx={{ width: '100%', height: '72px' }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Tooltip>
                </div>
                {/* Confirm Password Field */}
                <div style={{ margin: '0' }}>
                  <Typography variant="body1" sx={{ fontSize: '16px', margin: '0', color: '#1D407B', fontFamily: 'Montserrat, sans-serif' }}>
                    Confirm Password
                  </Typography>
                  <TextField
                    type="password"
                    variant="outlined"
                    required
                    placeholder="Password"
                    sx={{ width: '100%', height: '72px' }}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <Button type='submit' style={{
                    width: "60%",
                    backgroundColor: "#1F4A31",
                    color: "white",
                    padding: "10px",
                    fontWeight: "500",
                    fontSize: "1rem"
                  }}>Submit</Button>
                </div>
                <p style={{ textAlign: "center", margin: 0 }}>Already have an account?<MuLink sx={{ color: "blue", textDecoration: "none" }} to="/login" component={Link}> Login</MuLink></p>
                <p style={{ textAlign: "center", margin: 0 }}><MuLink sx={{ color: "blue", textDecoration: "none" }} to="/signup-business/" component={Link}>SignUp as a Business</MuLink></p>
              </Box>
            </div>
          </CardContent>
        </Card>
      </Container>
      <br></br><br></br>
      <Footer />
    </div>
  );
};

export default SignUpUser;
