import { useNavigate, Link } from "react-router-dom";
import {
  Card, Container, CardContent, Box, TextField, Button, Link as MuLink
} from '@mui/material';
import Nav from "../Nav.js";
import "../styles/Regipage.css";
import Footer from "../components/Footer"
import SwalFire from "../components/SwalFire"
import userSignUp from '../services/userSignUp';
import React, { useState } from "react";

const Regipage = () => {
  const [showPasswordPolicy, setShowPasswordPolicy] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [passwordValidations, setPasswordValidations] = useState({
    isLengthValid: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const handlePasswordFocus = () => {
    setShowPasswordPolicy(true);
  };

  const handlePasswordBlur = () => {
    setShowPasswordPolicy(false);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    setPasswordValidations({
      isLengthValid: newPassword.length >= 8 && newPassword.length <= 16,
      hasUpperCase: /[A-Z]/.test(newPassword),
      hasNumber: /\d/.test(newPassword),
      hasSpecialChar: /[!@#&$]/.test(newPassword),
    });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      SwalFire({ title: "Passwords did not match!", text: "", icon: "error", background: "red" })
    } else {
      const data = { fullName: companyName, email: email, password: password, isBusinessUser: true }
      const result = await userSignUp(data)
      if (result.email) {
        console.log("User added successfully!")
        SwalFire({
          title: "", text: "A verification link has been sent to your email",
          icon: "success", background: "green", color: "white", timer: 5000
        })
        navigate("/regipage_2", {state: {userEmail: result.email}})
      } else {
        SwalFire({
          title: result.message, text: "",
          icon: "error", background: "red", color: "white", timer: 5000
        })
      }
    }
  }

  return (
    <div>
      <Nav />
      <br/>
      <div style={{ height: "6rem" }}></div>
      <Container maxWidth="sm">
        <Card sx={{ width: '100%', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: "10px", overflow: "visible" }}>
          <CardContent sx={{ textAlign: "left" }}>
            <h1 style={{ marginBottom: 0 }}>Welcome to <span style={{ color: "#1F4A31" }}>LIAN</span> </h1>
            <p style={{ marginTop: 0, color: "#1F4A31" }}>SignUp your Business</p><br />
            <div>
              <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 0.75 }}>
                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B", marginTop: 0 }}>Name</p>
                <TextField
                  placeholder="Name of your business"
                  type="text"
                  variant="outlined"
                  required
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>Email</p>
                <TextField
                  placeholder="Email"
                  type="email"
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>Password</p>
                <div style={{ position: "relative", display: "flex", alignItems: "center", overflow: "visible" }}>
                  <TextField
                    placeholder="Password"
                    type="password"
                    variant="outlined"
                    required
                    value={password}
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                    onChange={handlePasswordChange}
                    style={{ flexGrow: 1 }}
                  />
                  {showPasswordPolicy && (
                    <div className="password-policy-container fade-in">
                      <h4>PASSWORD POLICY</h4>
                      <ul className="password-policy-list">
                        <li className={passwordValidations.isLengthValid ? "valid" : ""}>
                          <span className="checkmark">
                            {passwordValidations.isLengthValid && "✔"}
                          </span>
                          <strong>Minimum 8 to 16 characters</strong>
                        </li>
                        <li className={passwordValidations.hasUpperCase ? "valid" : ""}>
                          <span className="checkmark">
                            {passwordValidations.hasUpperCase && "✔"}
                          </span>
                          <strong>Contains an uppercase letter</strong>
                        </li>
                        <li className={passwordValidations.hasNumber ? "valid" : ""}>
                          <span className="checkmark">
                            {passwordValidations.hasNumber && "✔"}
                          </span>
                          <strong>Contains a number (0-9)</strong>
                        </li>
                        <li className={passwordValidations.hasSpecialChar ? "valid" : ""}>
                          <span className="checkmark">
                            {passwordValidations.hasSpecialChar && "✔"}
                          </span>
                          <strong>Contains a special character (!&@#$)</strong>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <p style={{ fontSize: "0.9rem", marginBottom: 0, color: "#1D407B" }}>Confirm password</p>
                <TextField
                  placeholder="Confirm Password"
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  variant="outlined"
                  required
                />
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <Button
                    style={{
                      width: "60%", backgroundColor: "#1F4A31",
                      color: "white", borderRadius: "5px",
                      padding: "10px", fontWeight: "500", fontSize: "1rem",
                    }}
                    type="submit"
                  >
                    Next
                  </Button><br /><br />
                  <p style={{ textAlign: "center", margin: 0 }}>Already have an account?<MuLink sx={{ color: "blue", textDecoration: "none" }} to="/login" component={Link}> Login</MuLink></p>
                  <p><MuLink sx={{ color: "blue", textDecoration: "none" }} to="/signup-user/" component={Link}>SignUp as a User</MuLink></p>
                  <br />
                </div>
              </Box>
            </div>
          </CardContent>

        </Card>
      </Container>
      <br />
      <Footer />

    </div>
  );
};

export default Regipage;
