import React from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

function ImageCarousel({ images, children }) {
    const [activeIndex, setActiveIndex] = React.useState(0);

    return (
        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
            <Swiper
                modules={[Autoplay]}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                loop={true}
                style={{ width: '100%', height: '100%' }}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <Box
                            component="img"
                            src={image}
                            alt={`Slide ${index + 1}`}
                            sx={{
                                width: '100%',
                                height: { xs: '400px', md: '400px', lg: '500px' },
                                objectFit: 'cover',
                            }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            {children}

            {/* Custom Pagination using MUI */}
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '30px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 2,
                }}
            >
                {images.map((_, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: activeIndex === index ? '16px' : '12px',
                            height: '4px',
                            backgroundColor: activeIndex === index ? '#fff' : 'rgba(255, 255, 255, 0.6)',
                            borderRadius: '2px',
                            margin: '0 4px',
                            transition: 'width 0.3s',
                        }}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default ImageCarousel;
