import React, { useEffect, useState, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Menu,
  MenuItem,
  Link as MuiLink,
  Avatar,
  IconButton,
  Tooltip
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { logoWithSlogan } from '../assets'; // Adjust the path as necessary
import SwalFire from './SwalFire';
import SearchBarComponent from './SearchBar'; // Import the SearchBar component
import userServices from "../services/user";
import { AuthContext } from './AuthContext'; // Import AuthContext

const { userDetail } = userServices;

function Header({showSearchBar, isAuthenticated, handleNavigateHome, userRole }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState({});
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Consume AuthContext
  const { logout } = useContext(AuthContext);
  // Handle menu opening
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu closing
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle logout action
  const handleLogout = () => {
    logout(); // Call the logout function from context
    SwalFire({
      title: "Logged Out!",
      text: "",
      icon: "success",
      background: "green",
      color: "white",
      timer: 5000
    });
    setAnchorEl(null)
    handleNavigateHome(); // Navigate to home page
  };

  // Fetch user details when authenticated
  useEffect(() => {
    const userUuid = localStorage.getItem("uuid");
    const fetchDetail = async () => {
      try {
        const detail = await userDetail(userUuid);
        setUser(detail);
      } catch (error) {
        console.error("Error fetching user detail:", error);
      }
    };
    console.log(isAuthenticated, "<----------------")
    if (userUuid) {
      fetchDetail();
    }
  }, [isAuthenticated]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.paper,
          boxShadow: 'none',
          minHeight: showSearchBar ? '100px !important' : '80px !important',
        }}
      >
        <Toolbar
          sx={{
            position: 'relative',
            minHeight: showSearchBar ? '100px !important' : '80px !important',
            alignItems: 'flex-start',
            padding: '10px 24px',
          }}
        >
          {/* Logo Section */}
          <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: '8px' }}>
            <MuiLink component={Link} to="/" sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component="img"
                src={logoWithSlogan}
                alt="Logo with Slogan"
                sx={{ height: '50px', width: 'auto' }}
              />
            </MuiLink>
          </Box>

          {/* Centered Search Bar */}
          {showSearchBar && (
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
                minWidth: '400px',
                maxWidth: '900px',
                '@media (max-width: 900px)': {
                  width: '70%',
                },
                '@media (max-width: 600px)': {
                  width: '90%',
                  minWidth: '280px',
                },
              }}
            >
              <SearchBarComponent />
            </Box>
          )}

          {/* Auth Section */}
          <Box sx={{ marginLeft: 'auto', paddingTop: '8px' }}>
            {isAuthenticated && userRole === "user" ? (
              <div>
                <Tooltip title="User Profile">
                  <IconButton
                    onClick={handleMenu}
                    size="small"
                    aria-controls={open ? 'profile-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <p style={{ marginRight: "10px" }}>{user?.fullName}</p>
                    <Avatar src="/path/to/user-image.jpg" alt="User Profile" />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="profile-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 3,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem>Profile</MenuItem>
                  <MenuItem>Settings</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            ) : isAuthenticated && userRole === "business" ? (
              <div>
                <Tooltip title="User Profile">
                  <IconButton
                    onClick={handleMenu}
                    size="small"
                    aria-controls={open ? 'profile-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <p style={{ marginRight: "10px" }}>{user?.fullName}</p>

                    <Avatar src="/path/to/user-image.jpg" alt="User Profile" />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="profile-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 3,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))',
        
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <h3 style={{padding: "0 15px"}}>Business User</h3>
                  <MenuItem>Profile</MenuItem>
                  <MenuItem>Settings</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            ) : (
              <>
                <MuiLink
                  component={Link}
                  to="/login"
                  sx={{
                    marginRight: theme.spacing(2),
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.darkGreen.main,
                    textDecoration: 'none',
                    fontSize: theme.typography.subtitle1.fontSize,
                    fontWeight: 600,
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  Login
                </MuiLink>
                <Button
                  variant="contained"
                  color="darkGreen"
                  onClick={handleMenu}
                  sx={{
                    borderRadius: '20px',
                    px: 3,
                    py: 1,
                    textTransform: 'none',
                    fontSize: theme.typography.subtitle1.fontSize,
                    backgroundColor: theme.palette.darkGreen.main,
                    '&:hover': { backgroundColor: theme.palette.darkGreen.dark || '#163922' },
                  }}
                >
                  Sign Up
                </Button>
              </>
            )}

            {!isAuthenticated && (
              <Menu
              anchorEl={anchorEl}
              id="profile-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 3,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))',
    
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem component={Link} to="/signup-user" onClick={handleClose}>
                  Sign Up as User
                </MenuItem>
                <MenuItem component={Link} to="/signup-business" onClick={handleClose}>
                  Sign Up as Business
                </MenuItem>
              </Menu>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Spacer to account for fixed AppBar */}
    </>
  );
}

export default Header;
