import React, { useState } from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GridModal = ({ open, onClose, photos }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (photo) => {
        setSelectedImage(photo);
    };

    const handleBackToGrid = () => {
        setSelectedImage(null);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="grid-modal"
            aria-describedby="A modal to display images in a grid or enlarged view"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'rgba(0, 0, 0, 0.85)',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '90%',
                    maxWidth: '1200px',
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    borderRadius: 6,
                    boxShadow: 24,
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                }}
            >
                {/* Back Button */}
                {selectedImage && (
                    <IconButton
                        onClick={handleBackToGrid}
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            backgroundColor: 'primary.main',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            },
                            zIndex: 10,
                            width: 45,
                            height: 45,
                            boxShadow: 3,
                        }}
                        aria-label="Back to gallery"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                )}

                {/* Close Button */}
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'primary.main',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                        zIndex: 10,
                        width: 45,
                        height: 45,
                        boxShadow: 3,
                    }}
                    aria-label="Close modal"
                >
                    <CloseIcon />
                </IconButton>

                {selectedImage ? (
                    // Enlarged Image View
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        {/* Selected Image */}
                        <Box
                            component="img"
                            src={selectedImage}
                            alt="Selected Image"
                            sx={{
                                width: '100%',
                                maxHeight: '80vh',
                                objectFit: 'contain',
                                borderRadius: 2,
                            }}
                        />
                    </Box>
                ) : (
                    // Grid View of Images
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)' },
                            gap: 2,
                            pt: 2,
                        }}
                    >
                        {photos.map((photo, index) => (
                            <Box
                                key={index}
                                component="img"
                                src={photo}
                                alt={`Gallery Image ${index + 1}`}
                                onClick={() => handleImageClick(photo)}
                                sx={{
                                    width: '100%',
                                    height: '200px',
                                    objectFit: 'cover',
                                    borderRadius: 2,
                                    cursor: 'pointer',
                                    transition: 'transform 0.3s ease',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                    },
                                }}
                            />
                        ))}
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default GridModal;
