import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import emailVerify from "../services/emailVerify"
import SwalFire from '../components/SwalFire';


const EmailVerify = () => {
    const { id, key } = useParams();
    const navigate = useNavigate()
    useEffect(() => {
        async function verifyUser() {
            try {
                const result = await emailVerify(id, key);
                if (result.message) {
                    console.log('Verification result:', result);
                    SwalFire({title:"Email Verified", icon:"success", timer:5000, color:"white", background:"green"})
                    navigate("/")

                } else {
                    SwalFire({title:"Email Not Verified", icon:"error", timer:5000, color:"white", background:"red"})
                    navigate("/")
                }
            } catch (error) {
                console.error('Verification failed:', error);
                SwalFire({title:"Email Not Verified", icon:"error", timer:5000, color:"white", background:"red"})
                navigate("/")
            }
        }

        verifyUser();

    })

    return (
        <></>
    );

}

export default EmailVerify