import Swal from 'sweetalert2';


const SwalFire = ({
    title,
    text="",
    icon = 'info',
    confirmButtonText = 'OK',
    position = 'bottom-end',
    footer = "",
    toast = true,
    timer = 3000,
    background = '#f8f9fa',
    color = 'white',
    timerProgressBar = true
}) => {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        background: background,
        color: color,
        showCloseButton: true,
        footer: footer,
        position: position,
        toast: toast,
        timer: timer, // Optional: dismiss after 5 seconds
        timerProgressBar: timerProgressBar,
        confirmButtonText: confirmButtonText
    });
};

export default SwalFire;
