import React, { useState } from 'react';
import {
  Box,
  Typography,
  Rating,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Tooltip,
  Button
} from '@mui/material';
import PhotoModal from './PhotoModal';
import ReportIcon from '@mui/icons-material/Report';
import leoProfanity from 'leo-profanity';

// Initialize leo-profanity with the default dictionary
leoProfanity.loadDictionary();

const ReviewCard = ({ review, fullWidth, onDelete }) => {
  const [profanityError, setProfanityError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };

  const handleClose = () => setModalOpen(false);

  const handleReportDialogOpen = () => {
    setReportReason('');
    setReportDialogOpen(true);
  };

  const handleReportReasonChange = (e) => {
    const reason = e.target.value;
    setReportReason(reason);
    // Check for profanity
    const hasProfanity = leoProfanity.check(reason);
    setProfanityError(hasProfanity);
  };

  const handleReportDialogClose = () => setReportDialogOpen(false);

  const handleConfirmReport = () => {
    setSnackbarOpen(true);
    handleReportDialogClose();
    if (onDelete) onDelete(); // Call the delete function passed from parent
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          mb: 2,
          p: 2,
          border: '1px solid #ddd',
          borderRadius: '8px',
          backgroundColor: '#fff',
          maxWidth: fullWidth ? '100%' : '800px',
          width: '100%',
          boxShadow: 3,
        }}
      >
        <Avatar
          sx={{
            bgcolor: 'primary.main',
            mr: 2,
            width: 56,
            height: 56,
            fontSize: '1.5rem',
          }}
        >
          {review.reviewer.charAt(0).toUpperCase()}
        </Avatar>
        <Box sx={{ flex: 1, textAlign: 'left' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {review.reviewer}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" color="textSecondary">
                {review.date}
              </Typography>
              <Tooltip title="Report this review" arrow>
                <IconButton
                  onClick={handleReportDialogOpen}
                  sx={{ color: 'grey.500', '&:hover': { color: 'grey.800' } }}
                >
                  <ReportIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Rating value={review.rating} readOnly size="small" sx={{ mb: 1 }} />
          <Typography variant="body1" sx={{ mb: 1 }}>
            {review.comment}
          </Typography>
          {review.photos && review.photos.length > 0 && (
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              {review.photos.slice(0, 3).map((photo, index) => (
                <Box
                  key={index}
                  component="img"
                  src={photo}
                  alt={`Review Photo ${index + 1}`}
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: 1,
                    objectFit: 'cover',
                    cursor: 'pointer',
                    boxShadow: 2,
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={() => handleOpen(index)}
                />
              ))}
              {review.photos.length > 3 && (
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ alignSelf: 'center', cursor: 'pointer' }}
                  onClick={() => handleOpen(0)}
                >
                  +{review.photos.length - 3} more
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>

      {/* Use the PhotoModal component */}
      <PhotoModal
        open={modalOpen}
        onClose={handleClose}
        photos={review.photos}
        initialIndex={currentIndex}
      />

      {/* Report Dialog */}
      <Dialog
        open={reportDialogOpen}
        onClose={handleReportDialogClose}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            maxWidth: 500,
            width: '100%',
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem', mb: 1, px: 3 }}>
          Report Review
        </DialogTitle>
        <DialogContent sx={{ pb: 1, px: 3 }}>
          <Typography sx={{ mb: 1, fontSize: '1.1rem' }}>
            Why do you want to report this review?
          </Typography>
          <TextField
            margin="dense"
            label="Reason"
            type="text"
            fullWidth
            variant="outlined"
            value={reportReason}
            onChange={handleReportReasonChange}
            error={profanityError}
            helperText={profanityError ? "Please avoid using profanity." : ""}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            onClick={handleReportDialogClose}
            variant="outlined"
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              fontSize: '1.1rem',
              paddingX: 3,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmReport}
            variant="contained"
            color="primary"
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              fontSize: '1.1rem',
              paddingX: 3,
            }}
            disabled={!reportReason.trim() || profanityError}
          >
            Report
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Review reported successfully!"
      />
    </>
  );
};

export default ReviewCard;
