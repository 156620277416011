/* This is an interceptor */
const fetchClient = async (url, options = {}) => {
    const apiURL = process.env.REACT_APP_API_URL
    const finalURL = `${apiURL}${url}`
    const token = localStorage.getItem('token');
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    if (token) {
        defaultHeaders['Authorization'] = `Token ${token}`;
    }
    const requestOptions = {
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    };

    try {
        const response = await fetch(finalURL, requestOptions);
        console.log(response)
        if (!response.ok) {
            if (response.status === 401) {
                throw new Error('Unauthorized - Redirecting to login');
            } else {
                return response;
            }
        } else {
            return response
        };

    } catch (error) {
        console.error('Fetch error:', error.message);
        throw error;
    }
};

export default fetchClient;
