import React from 'react';
import { APIProvider, Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const MapComponent = ({ businesses, center, zoom }) => {
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API;

  const defaultCenter = {
    lat: 42.2626,
    lng: -71.8023,
  };

  const defaultZoom = 12;

  // Define map options to disable specific controls
  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: true, // Enable zoom controls
    gestureHandling: 'greedy',
    disableDefaultUI: true,
  };

  return (
    <div style={mapContainerStyle}>
      {GOOGLE_MAPS_API_KEY ? (
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY} libraries={['marker']}>
          <Map
            mapId="976e6c29bc5d03d3" // Your actual Map ID
            defaultCenter={center || defaultCenter}
            defaultZoom={zoom || defaultZoom}
            options={mapOptions}
            style={mapContainerStyle}
          >
            {businesses.map((business) => (
              <AdvancedMarker
                key={business.id}
                position={business.location}
                title={business.name}
              >
              </AdvancedMarker>
            ))}
          </Map>
        </APIProvider>
      ) : (
        <p>Google Maps API key is missing or invalid.</p>
      )}
    </div>
  );
};

export default React.memo(MapComponent);