// src/components/CategoryCarousel.js

import React, { useRef, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { useNavigate } from 'react-router-dom';

// Import Material-UI Icons for navigation
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Import the icons for the categories
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AttractionsIcon from '@mui/icons-material/Attractions';
import ConstructionIcon from '@mui/icons-material/Construction';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import CategoryIcon from '@mui/icons-material/Category';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import { useTheme } from '@mui/material/styles'; // Import useTheme

const CategoryCarousel = ({ categories }) => {
  const theme = useTheme(); // Use theme for colors
  const navigate = useNavigate();
  const swiperRef = useRef(null); // Reference to Swiper instance

  // State to track if swiper is at the beginning or end
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleCategoryClick = (categoryName) => {
    // Navigate to the Explore page with category filter
    navigate(`/explore/${encodeURIComponent(categoryName)}`);

  };

  if (!categories || categories.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography variant="h6">No categories available.</Typography>
      </Box>
    );
  }

  // Map of icon names to icon components
  const iconMap = {
    HealthAndSafety: HealthAndSafetyIcon,
    ShoppingCart: ShoppingCartIcon,
    Attractions: AttractionsIcon,
    Construction: ConstructionIcon,
    BrunchDining: BrunchDiningIcon,
    Category: CategoryIcon,
    AssuredWorkload: AssuredWorkloadIcon,
  };

  // Handler to update the state based on swiper's current position
  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  return (
    <Box sx={{ position: 'relative', maxWidth: '1000px', margin: '0 auto', padding: '0 30px' }}> 
      {/* Conditionally Render Previous Button */}
      {!isBeginning && (
        <IconButton
          aria-label="previous categories"
          onClick={() => swiperRef.current?.slidePrev()}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '-20px', // Adjust left position to keep button close to the carousel
            transform: 'translateY(-50%)',
            backgroundColor: 'primary.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
            zIndex: 20,  
            width: 45,   // Matching button size to Best Restaurants
            height: 45,
            boxShadow: 3,
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
      )}

      {/* Conditionally Render Next Button */}
      {!isEnd && (
        <IconButton
          aria-label="next categories"
          onClick={() => swiperRef.current?.slideNext()}
          sx={{
            position: 'absolute',
            top: '50%',
            right: '-20px', // Adjust right position to keep button close to the carousel
            transform: 'translateY(-50%)',
            backgroundColor: 'primary.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
            zIndex: 20,  
            width: 45,   // Matching button size to Best Restaurants
            height: 45,
            boxShadow: 3,
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}

      {/* Swiper Carousel */}
      <Swiper
        modules={[Navigation]}
        spaceBetween={60}
        slidesPerView={3}
        slidesPerGroup={3}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          // Initialize the state based on the initial swiper position
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
        onSlideChange={handleSlideChange}
        loop={false}
        style={{ padding: '20px 0' }}
      >
        {categories.map((category, index) => {
          const IconComponent = iconMap[category.icon];

          return (
            <SwiperSlide
              key={index}
              style={{ 
                flex: '1',
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                margin: '0 10px',
              }} 
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                onClick={() => handleCategoryClick(category.label)}
              >
                <Box
                  sx={{
                    width: '180px',
                    height: '180px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 1,
                    boxShadow: 3,
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.1)',
                    },
                  }}
                >
                  {IconComponent && (
                    <IconComponent
                      sx={{
                        fontSize: '80px',
                        color: theme.palette.darkGreen.main, // Use dark green from theme
                      }}
                    />
                  )}
                </Box>
                <Typography variant="body1" align="center" sx={{ fontWeight: 500 }}>
                  {category.label}
                </Typography>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default CategoryCarousel;
