import React, { createContext, useState, useEffect } from 'react';

// Create the AuthContext
export const AuthContext = createContext();

// AuthProvider component to wrap around your app
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userUUID, setUserUUID] = useState(null);

  // Load authentication data from localStorage on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    const uuid = localStorage.getItem('uuid');

    if (token && role) {
      setIsAuthenticated(true);
      setUserRole(role);
      setUserUUID(uuid);
    }
  }, []); // Empty dependency array ensures this runs once on mount

  // Logout function to clear authentication data
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('uuid');
    setIsAuthenticated(false);
    setUserRole(null);
    setUserUUID(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, userUUID, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
