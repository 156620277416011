import fetchClient from "../fetchClient";

const userLogin = async (payload) =>{
    const url = "/auth/login";
    try {
        const requestOptions = {method: "POST", body: JSON.stringify(payload)}
        const response = await fetchClient(url, requestOptions)
        console.log(response)
        if (!response.ok) {
          throw new Error('Login failed');
        }
  
        const data = await response.json();
        console.log('Login successful:', data);
        
        localStorage.setItem('token', data.token);
        localStorage.setItem('role', data.role);
        localStorage.setItem('uuid', data.uuid);
        return data;
      } catch (error) {
        console.log(error);
      }
}

export default userLogin
