import SwalFire from "../components/SwalFire";
import fetchClient from "../fetchClient";

const userSignUp = async (payload) =>{
    const url = "/auth/register/";
    try {
        const requestOptions = {method: "POST", body: JSON.stringify(payload)}
        const response = await fetchClient(url, requestOptions)
        console.log(response)
        if (response.status === 500) {
          throw new Error('SignUp failed');
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        SwalFire({title: "SignUp failed", text: error, background: "red", icon: "error"})
        console.log(error);
      }
}

export default userSignUp
