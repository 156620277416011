// src/services/LocationContext.js
import React, { createContext, useState, useEffect } from 'react';

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState({ city: null, region: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        // Try primary API first (ipapi.co)
        const response = await fetch('https://ipapi.co/json/', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        if (!data || !data.city || !data.region_code) {
          throw new Error('Invalid data received from API');
        }

        setLocation({
          city: data.city,
          region: data.region_code,
        });

      } catch (error) {
        console.error("Location fetch error details:", error.message);
        
        // If primary API fails, try backup API (ipapi.com)
        try {
          const backupResponse = await fetch('https://api.ipapi.com/api/check?access_key=YOUR_API_KEY');
          const backupData = await backupResponse.json();
          
          if (backupData && backupData.city && backupData.region_code) {
            setLocation({
              city: backupData.city,
              region: backupData.region_code,
            });
          } else {
            throw new Error('Invalid backup API data');
          }
        } catch (backupError) {
          console.error("Backup location fetch failed:", backupError.message);
          // Final fallback
          setLocation({ city: "San Francisco", region: "CA" });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchLocation();
  }, []);

  return (
    <LocationContext.Provider value={{ ...location, setLocation, loading }}>
      {children}
    </LocationContext.Provider>
  );
};
